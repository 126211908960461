/**********************
Global Styles
**********************/
*,
*:after,
*:before {
  box-sizing: border-box; }

/** Fixing bootstrap columns for safari **/
.row:after,
.row:before {
  display: inline-block !important; }

body {
  background-color: #fff;
  color: #333;
  font-size: 18px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Colfax Regular"; }

a,
a:focus,
a:hover,
a:visited {
  text-decoration: none;
  outline: 0; }

section {
  padding: 80px 0; }

button {
  border: 0;
  background-color: transparent; }
  button:hover {
    cursor: pointer; }
  button:focus {
    outline: 0; }

.container {
  max-width: 1140px; }

.section-title {
  font-size: 48px;
  color: #1ca568;
  font-weight: 300;
  color: #040707;
  margin-bottom: 0;
  font-family: "Colfax Black"; }

.sub-heading {
  font-size: 22px;
  line-height: 28px;
  color: #a48242;
  font-family: "Colfax Medium"; }

strike {
  color: #797979; }

.img-container {
  text-align: center; }

.sale-text {
  font-weight: 700;
  font-size: 22px;
  margin: 20px 0;
  color: #a48242; }

.modal {
  background-color: rgba(0, 0, 0, 0.8); }

/* Buttons */
.btn-prime,
.btn-prime:link,
.btn-prime:visited {
  padding: 10px 25px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-radius: 0;
  display: inline-block;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  box-shadow: none !important;
  outline: 0; }

.btn-prime:hover {
  opacity: 0.9; }

.btn-prime-dark,
.btn-prime-dark:focus,
.btn-prime-dark:hover {
  border: 2px solid #1ca568;
  background-color: #1ca568;
  color: #fff; }

.btn-prime-border,
.btn-prime-border:focus,
.btn-prime-border:hover {
  background-color: transparent;
  border: 2px solid #040707;
  color: #040707; }

.flex-container {
  -webkit-box-align: center;
  align-items: center; }

/** MEDIA QUERIES **/
@media only screen and (max-width: 1199px) {
  .container {
    max-width: 1060px; } }

@media only screen and (max-width: 991px) {
  section {
    padding: 50px 0; }
  .navbar-dark .navbar-nav .nav-link,
  .navbar-dark .navbar-nav .nav-link:active,
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover,
  .navbar-dark .navbar-nav .nav-link:visited {
    font-size: 12px; }
  .container {
    max-width: 890px; } }

@media only screen and (max-width: 767px) {
  #main-nav {
    display: none !important; }
  .container {
    max-width: 100%; }
  .mobile-menu img {
    height: 34px; }
  .mobile-menu input + label {
    right: 15px; }
  .mobile-menu .navbar-brand {
    left: 15px;
    padding: 17px 15px 15px 0; } }

@media only screen and (max-width: 600px) {
  .section-title {
    font-size: 30px; }
  p {
    font-size: 16px; } }

@media only screen and (max-width: 475px) {
  .mobile-menu .navbar-brand {
    left: 15px; } }

@media only screen and (max-width: 450px) {
  section {
    padding: 40px 0; }
  .sub-heading {
    font-size: 18px;
    line-height: 24px; }
  .btn-prime,
  .btn-prime:link,
  .btn-prime:visited {
    font-size: 16px; }
  .sale-text {
    font-size: 22px; } }

@media only screen and (max-width: 375px) {
  .section-title {
    font-size: 22px !important; } }

/**********************
End of Global Styles
**********************/
/**********************
Navigation
**********************/
nav {
  padding: 0 !important; }

nav .nav-item {
  line-height: 36px; }

.buy-now {
  background-color: #1ca568 !important;
  color: #fff !important; }

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:active,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:visited {
  color: #1ca568;
  font-size: 14px;
  outline: 0;
  letter-spacing: .05em;
  font-weight: 700;
  padding: 10px 15px !important;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.navbar-dark .navbar-nav .nav-link:hover {
  background-color: #1ca568;
  color: #fff; }

.navbar-brand:hover,
.navbar-dark .navbar-brand {
  font-weight: 700;
  color: #1ca568; }

.navbar-dark .navbar-brand img {
  height: 45px; }

.navbar-nav .dropdown-menu {
  left: auto;
  right: 0;
  border: 0;
  border-radius: 0;
  margin-top: 0; }

.dropdown-item {
  background-color: #fff; }

.dropdown-menu.show {
  background-color: #fff;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out; }

.dropdown-item.active, .dropdown-item:active {
  background-color: #f8f8f8;
  color: #212529; }

.dropdown-item:focus, .dropdown-item:hover {
  color: #fff;
  background-color: #1ca568; }

.cta-btn {
  background-color: #1ca568; }

.cta-btn .nav-link {
  color: #fff !important; }

/* Mobile Menu */
header {
  background-color: #fff;
  position: relative;
  box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2); }

@media screen and (min-width: 768px) {
  .mobile-menu {
    display: none; } }

@media screen and (max-width: 767px) {
  .mobile-nav .btn-prime.btn-prime-dark {
    color: #fff;
    font-size: 26px;
    width: 100%; }
  input#burger {
    display: none; }
  .mobile-menu .navbar-brand {
    font-weight: 700;
    color: #1ca568; }
  .mobile-menu input:not(:checked) .mobile-links {
    display: none; }
  .mobile-menu input + label {
    position: fixed;
    top: 20px;
    right: 20px;
    height: 20px;
    width: 34px;
    z-index: 5;
    padding: 0;
    margin-bottom: 0; }
  .mobile-menu input + label span {
    position: absolute;
    width: 100%;
    height: 2px;
    top: 60%;
    margin-top: -1px;
    left: 0;
    display: block;
    background: #1ca568;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }
  .mobile-menu input + label span:first-child {
    top: 4px; }
  .mobile-menu input + label span:last-child {
    top: 20px; }
  .mobile-menu label:hover {
    cursor: pointer; }
  .mobile-menu input:checked + label span {
    opacity: 0;
    top: 50%; }
  .mobile-menu input:checked + label span:first-child {
    opacity: 1;
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  .mobile-menu input:checked + label span:last-child {
    opacity: 1;
    -webkit-transform: rotate(-405deg);
    transform: rotate(-405deg); }
  .mobile-menu input ~ nav {
    background: #fff;
    box-shadow: 2px 2px 5px 0 rgba(173, 173, 173, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    z-index: 3;
    overflow: hidden; }
  .mobile-menu nav {
    position: relative; }
  .mobile-menu input ~ nav > ul {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    padding: 0;
    margin-bottom: 0;
    width: 80%; }
  .mobile-menu input ~ nav > ul > li {
    display: none; }
  .mobile-menu input ~ nav > ul > li > a {
    color: #1ca568;
    font-weight: 700;
    display: block;
    letter-spacing: .05em;
    padding: 15px;
    font-size: 22px; }
  .mobile-menu input:checked ~ nav {
    height: 100%;
    -webkit-transition-delay: 0s;
    transition-delay: 0s; }
  .mobile-links {
    list-style: none; }
  .mobile-menu input:checked ~ nav > ul > li {
    display: initial; }
  .mobile-menu .navbar-brand {
    position: absolute;
    top: 0;
    left: 15px;
    z-index: 3000;
    padding: 17px 15px 15px 0; } }

@media screen and (max-width: 575px) {
  .mobile-menu input ~ nav > ul > li > a,
  .mobile-nav .btn-prime.btn-prime-dark {
    font-size: 18px; } }

@media screen and (max-width: 450px) {
  .mobile-menu .navbar-brand img {
    height: 32px; } }

/**********************
Hero
**********************/
.hero {
  background-color: #f8f8f8;
  margin-top: 56px;
  padding: 60px 0; }
  .hero .section-title {
    font-size: 40px;
    margin-bottom: 0; }
    .hero .section-title span {
      display: block;
      font-weight: 400;
      font-size: 26px;
      opacity: 0.7;
      text-transform: initial; }
  .hero .sub-heading {
    color: #505050; }

/* Media Queries */
@media only screen and (max-width: 991px) {
  .hero {
    text-align: center; }
    .hero .flex-container {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      flex-direction: column-reverse; }
    .hero img {
      height: 400px;
      margin-bottom: 20px; } }

@media only screen and (max-width: 767px) {
  .hero {
    margin-top: 60px; } }

@media only screen and (max-width: 600px) {
  .hero .section-title {
    font-size: 34px; }
    .hero .section-title span {
      font-size: 23px; }
  .hero img {
    height: 300px; } }

@media only screen and (max-width: 450px) {
  .hero {
    padding: 40px 0; }
    .hero .section-title {
      font-size: 26px; }
      .hero .section-title span {
        font-size: 20px;
        margin-bottom: 15px; } }

@media only screen and (max-width: 380px) {
  .hero img {
    height: 200px; } }

/************************************
 End of Hero
************************************/
/*************************
Footer
*************************/
footer {
  text-align: center;
  background-color: #1ca568;
  padding: 30px 0; }
  footer p {
    margin-bottom: 5px;
    font-size: 14px;
    color: #fff; }
  footer .image img {
    height: 7px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out; }

/**********************
End of Footer
**********************/
/**********************
Site Specific Styles
**********************/
/*Regular version*/
@font-face {
  font-family: "Colfax Regular";
  src: url("../fonts/Colfax-Regular.otf"); }

/*Medium version*/
@font-face {
  font-family: "Colfax Medium";
  src: url("../fonts/Colfax-Medium.otf"); }

/*Black version*/
@font-face {
  font-family: "Colfax Black";
  src: url("../fonts/Colfax-Black.otf"); }

body {
  font-family: "Colfax Regular"; }

strike {
  color: #040707; }

strong {
  font-family: "Colfax Medium"; }

.check {
  height: 20px;
  margin-right: 4px; }

section.people:nth-child(odd) {
  background-color: #f8f8f8; }

#main-nav {
  background-color: #040707; }

.navbar-dark .navbar-brand img {
  height: 54px; }

nav .nav-item {
  line-height: 50px; }

.cta-btn .nav-link {
  color: #fff !important; }

.cta-btn .nav-link:hover {
  color: white !important; }

.navbar-lg .navbar-nav > li > a {
  font-weight: 400 !important; }

.cta-btn {
  background-color: #1ca568; }

.navbar-dark .navbar-nav .nav-link,
.navbar-dark .navbar-nav .nav-link:active,
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:visited {
  color: white;
  font-weight: 400; }

.mobile-nav {
  background-color: #231f20 !important; }

.divider {
  height: 20px;
  background-color: #040707; }

/**********************
Hero
**********************/
#hero {
  position: relative;
  background-color: #040707;
  background-image: url("../img/hero-2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 800px;
  background-position: top;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 100px 0;
  text-align: center;
  margin-top: 0;
  color: #fff; }
  #hero .container {
    position: relative;
    z-index: 1; }
  #hero::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(120deg, #000, #000);
    opacity: .5; }
  #hero .hero__text-area {
    position: relative; }
    #hero .hero__text-area .x-lines {
      position: absolute;
      height: 400px;
      right: 50px;
      top: -60px;
      z-index: -1; }
    #hero .hero__text-area .o-lines {
      height: 150px;
      margin-bottom: 20px; }
  #hero .section-title {
    font-size: 70px;
    color: #fff;
    margin-bottom: 5px;
    line-height: 1.2; }
    #hero .section-title span {
      font-size: inherit;
      color: #fff !important; }
  #hero .sub-heading {
    font-size: 26px;
    color: #1ca568;
    outline: none;
    font-family: "Colfax Black";
    text-transform: uppercase;
    line-height: 1.3; }
  #hero form .btn-prime {
    margin-top: 26px; }
  #hero form .form-control {
    padding: .75rem;
    font-size: 16px;
    border-radius: 0;
    border: 0; }
  #hero form .form__fields {
    display: -webkit-box;
    display: flex; }
  #hero form #fullName {
    margin-right: 8px; }

input#mc-embedded-subscribe {
  cursor: pointer; }

#mc_embed_signup div.mce_inline_error {
  margin: 1em 0 !important;
  padding: 5px 10px;
  background-color: #1ca568 !important;
  color: #fff; }

.indicates-required {
  margin: 10px 0; }

#mc_embed_signup input.mce_inline_error {
  background-color: red;
  color: #fff; }

#mc_embed_signup input.mce_inline_error::-webkit-input-placeholder {
  color: #fff; }

#mc_embed_signup input.mce_inline_error::-moz-placeholder {
  color: #fff; }

#mc_embed_signup input.mce_inline_error::-ms-input-placeholder {
  color: #fff; }

#mc_embed_signup input.mce_inline_error::placeholder {
  color: #fff; }

#mce-success-response {
  color: #1ca568 !important;
  border: #1ca568 solid 3px !important;
  padding: 10px !important;
  text-transform: uppercase !important;
  font-weight: 700; }

@media (min-width: 1601px) {
  #hero {
    height: 1100px; } }

@media (max-width: 991px) {
  #hero .speakers {
    height: 300px; } }

@media (max-width: 600px) {
  #hero {
    background-image: url(../img/hero-mobile.jpg);
    background-size: cover;
    padding: 70px 0;
    height: auto; }
  #hero::after {
    opacity: .6; }
  #hero .hero__text-area .x-lines {
    position: absolute;
    height: 280px;
    right: 44px;
    top: -29px; }
  #hero .hero__text-area .o-lines {
    height: 100px; }
  #hero .section-title {
    font-size: 48px; }
  #hero .sub-heading {
    font-size: 20px; }
  #hero form .form__fields {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column; }
  #hero form #fullName {
    margin-right: 0;
    margin-bottom: 10px; }
  #hero .speakers {
    height: auto; } }

@media (max-width: 545px) {
  #hero .section-title {
    font-size: 28px; }
  #hero .hero__text-area .x-lines {
    position: absolute;
    height: 160px;
    right: 38px;
    top: -20px; }
  #hero .hero__text-area .o-lines {
    height: 70px; } }

@media (max-width: 575px) {
  input#mce-FNAME {
    margin-bottom: 13px; } }

@media (max-width: 450px) {
  #hero .sub-heading {
    font-size: 14px; } }

/**********************
Green
**********************/
.green {
  background-color: #1ca568;
  padding: 40px 0;
  text-align: center; }
  .green .section-title {
    color: #fff;
    font-size: 27px;
    letter-spacing: 1px; }

@media (max-width: 991px) {
  .green .section-title {
    color: #fff;
    font-size: 20px; } }

@media (max-width: 600px) {
  .green .section-title {
    color: #fff;
    font-size: 16px;
    line-height: 1.5; } }

/**********************
Synopsis
**********************/
.synopsis {
  position: relative;
  background-color: #fff;
  text-align: center; }
  .synopsis .section-header {
    margin-bottom: 16px;
    color: #040707; }
    .synopsis .section-header .sub-heading {
      margin-bottom: 0; }
  .synopsis .bullets {
    margin: 20px 0; }
    .synopsis .bullets em {
      font-weight: 600; }
  .synopsis .img-container {
    margin: 0 0 30px; }
  .synopsis .cta-container {
    text-align: center; }

/** MEDIA QUERIES **/
@media (max-width: 991px) {
  .synopsis .section-header {
    margin-bottom: 16px; } }

@media only screen and (max-width: 600px) {
  .synopsis .section-title {
    font-size: 40px; }
  .synopsis .bullets span {
    display: block; } }

@media only screen and (max-width: 450px) {
  .synopsis .section-title {
    font-size: 30px; } }

@media only screen and (max-width: 375px) {
  .synopsis__text-area {
    margin-top: 20px; } }

/**********************
About
**********************/
.about-paul {
  color: black;
  padding: 20px 0 0; }
  .about-paul .top-section-header {
    text-align: center;
    margin-bottom: 50px; }
    .about-paul .top-section-header .section-title {
      margin-bottom: 10px; }
  .about-paul .section-header {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 10px; }
    .about-paul .section-header .section-title {
      color: #040707;
      text-transform: uppercase;
      font-size: 34px; }
  .about-paul .pt-icon {
    height: 150px;
    margin-right: 15px; }
  .about-paul .socials .item {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 20px; }
    .about-paul .socials .item .icon {
      padding-right: 35px;
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center; }
      .about-paul .socials .item .icon img {
        height: 40px; }
    .about-paul .socials .item a {
      font-size: 30px;
      color: #040707;
      font-weight: 700;
      -webkit-transition: all 0.3s ease-in-out;
      transition: all 0.3s ease-in-out; }
      .about-paul .socials .item a:hover {
        opacity: .6;
        color: #1ca568; }

@media (max-width: 767px) {
  .about-paul {
    text-align: center;
    padding: 0; }
  .about-paul .top-section-header {
    margin-bottom: 30px; }
  .about-paul .section-header {
    -webkit-box-pack: center;
    justify-content: center; }
  .about-paul .text-area {
    padding-left: 0;
    margin-bottom: 60px; }
  .socials {
    max-width: 332px;
    margin: 0 auto; }
  .about-paul .socials .item {
    -webkit-box-pack: center;
    justify-content: center; } }

@media (max-width: 600px) {
  .about-paul .top-section-header {
    margin-bottom: 10px; } }

@media (max-width: 450px) {
  .about-paul .pt-icon {
    height: 150px;
    margin-right: 6px; }
  .about-paul .section-header {
    margin-bottom: 20px; }
  .about-paul .socials .item .icon img {
    height: 30px; }
  .about-paul .socials .item .icon {
    padding-right: 15px; }
  .about-paul .socials .item a {
    font-size: 20px; } }

/**********************
CTA
**********************/
.cta {
  background-color: #040707;
  text-align: center;
  color: #fff; }
  .cta img {
    margin-bottom: 30px;
    height: 150px; }
  .cta .section-title {
    color: #fff;
    margin-bottom: 16px; }

@media (max-width: 450px) {
  .cta img {
    height: auto;
    width: 200px;
    margin-bottom: 20px; } }

/**********************
Thank You Page
**********************/
.thank-you footer {
  width: 100%; }

.thank-you .inner .section-title {
  margin-bottom: 20px; }

.thank-you .inner a {
  color: #1ca568; }

.thank-you-cta .green-text {
  margin-bottom: 50px;
  font-size: 32px; }

.thank-you-cta img {
  height: auto; }

@media (max-width: 600px) {
  .thank-you-cta img {
    height: 300px; } }

@media (max-width: 600px) {
  .thank-you-cta img {
    height: auto; }
  .thank-you-cta .green-text {
    margin-bottom: 30px;
    font-size: 24px; } }
